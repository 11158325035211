import { useContext } from 'react';

import { HomeConfigContext, useHomeUBL } from '@domains/home-common';

import landing_banner_image from '../assets/landing_banner_left.png';
import { LandingBanner } from '../components/LandingBanner';
import { useLandingBannerService } from '../services/useLandingBannerService';

/** @public */
export function LandingBannerContainer() {
  const { clickLandingBanner, hide } = useLandingBannerService();
  const { appDownloadClickUBL } = useHomeUBL();
  const { page_id } = useContext(HomeConfigContext);
  return (
    <LandingBanner
      hidden={hide}
      src={landing_banner_image}
      alt='landing-banner'
      buttonText='앱으로 이동'
      onClick={() => {
        appDownloadClickUBL(page_id);
        clickLandingBanner();
      }}
    />
  );
}
