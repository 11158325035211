import { createContext, ReactNode, useContext } from 'react';

import { HomePath } from '../models/home-path';

/** @public */
export interface HomeConfigContextValue {
  path: HomePath;
  page_id: 'web_home';
}

/** @public */
export const HomeConfigContext = createContext<HomeConfigContextValue>({
  path: {
    auth: { root: '' },
    home: '',
    orders: '',
    root: '',
    search: '',
    cart: '',
    categories: {
      root: '',
    },
    product: {
      detail: '',
    },
    my_page: {
      root: '',
    },
  },
  page_id: 'web_home',
});

/** @public */
export const useHomeConfig = () => useContext(HomeConfigContext);

/** @public */
export interface HomeConfigProviderProps extends HomeConfigContextValue {
  children: ReactNode;
}

/** @public */
export function HomeConfigProvider({ children, ...value }: HomeConfigProviderProps) {
  return <HomeConfigContext.Provider children={children} value={value} />;
}
