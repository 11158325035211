import { QueryOptions, useQuery } from 'react-query';

import { RequestContext } from '@common/api-request';

import { getCartItemsCount } from '../graphql/getCartItemsCount.2';

export const cartItemsCountQueryOption = (
  context?: RequestContext,
): QueryOptions<number, unknown, number, 'getCartItemsCount'> => ({
  queryFn: async () => {
    const result = await getCartItemsCount(undefined, context ? { context } : undefined);
    return result.data.cart_items_count;
  },
  queryKey: 'getCartItemsCount',
});

export function useCartCountQuery() {
  return useQuery(cartItemsCountQueryOption());
}
