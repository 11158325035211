import { ReactNode, useEffect, useState } from 'react';

import { GetWebDdpCarousel_getWebDdpCarousel_item_item_list as UxGoodsCardItem } from '../graphql/ddp-carousel_types.2';
import { ProductItemCarousel } from './ProductItemCarousel';
import { ProductItemCarouselWithNavigation } from './ProductItemCarouselWithNavigation';

const MAX_WIDTH = 568;

interface WebProductItemCarouselProps {
  title?: ReactNode;
  productItemList?: UxGoodsCardItem[] | null;
  renderProductCard: (item: UxGoodsCardItem, position: number) => ReactNode;
}

export const WebProductItemCarousel = ({ title, productItemList, renderProductCard }: WebProductItemCarouselProps) => {
  const [overMaxWidth, setOverrMaxWidth] = useState(false);

  useEffect(() => {
    const { width } = window.document.documentElement.getBoundingClientRect();
    setOverrMaxWidth(width >= MAX_WIDTH);
  }, []);

  if (!productItemList?.length) return null;
  if (overMaxWidth)
    return (
      <ProductItemCarouselWithNavigation
        title={title}
        productItemList={productItemList}
        renderProductCard={renderProductCard}
      />
    );
  return <ProductItemCarousel title={title} productItemList={productItemList} renderProductCard={renderProductCard} />;
};
