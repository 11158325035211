import { useQuery } from 'react-query';
import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import { RequestContext } from '@common/api-request';

import { getWebBannerList } from '../graphql/banner.2';

/** @public */
export const queryKeys = createQueryKeyStore({
  banner: {
    list: null,
  },
});

/** @public */
export const bannerQueryOptions = (context?: RequestContext) => ({
  queryFn: async () => {
    const {
      data: { web_banner_list },
    } = await getWebBannerList({ type: 'new-4' }, { context });
    return web_banner_list;
  },
  queryKey: [queryKeys.banner.list],
});

export function useBannerQuery() {
  return useQuery(bannerQueryOptions());
}

export const useGetBanner = () => {
  const isNaverBot =
    typeof window !== 'undefined' && (/(Yeti)/i.test(navigator.userAgent) || /(Naverbot)/i.test(navigator.userAgent));

  return useQuery(['main_banner_list'], () => getWebBannerList({ type: 'new-4' }), {
    select: ({ data }) => data.web_banner_list?.item_list?.filter(({ image_url }) => image_url),
    enabled: !isNaverBot,
  });
};
