import { Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { web_max_width } from '@common/styles';

/** @public */
export const Main = styled.main`
  max-width: ${web_max_width}px;
  min-height: 100vh;
  margin: 0 auto;
  background: ${Static_White};
`;
