import { Gray_Light_600, Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { Navigation, Swiper, SwiperSlide } from '@common/carousel/swiper';
import { SliderNavigatorArrowLeftIcon } from '@common/icons';
import { SWIPER_STYLE } from '@common/styles';

import { ProductItemCarouselProps } from './ProductItemCarousel';

export const ProductItemCarouselWithNavigation = ({
  title,
  productItemList,
  renderProductCard,
}: ProductItemCarouselProps) => {
  if (!productItemList?.length) return null;
  return (
    <section>
      <SwiperLayout>
        {title && <SwiperCarouselTitle>{title}</SwiperCarouselTitle>}
        <SwiperCarousel
          modules={[Navigation]}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          slidesPerView={3}
          slidesPerGroup={3}
        >
          {productItemList?.map((item, index) => (
            <CardSwiperSlide key={`${item.title}${index}`}>{renderProductCard(item, index)}</CardSwiperSlide>
          ))}
          <Button type='button' className='swiper-button-prev'>
            <SliderNavigatorArrowLeftIcon color={Gray_Light_600} />
          </Button>
          <Button type='button' className='swiper-button-next'>
            <SliderNavigatorArrowLeftIcon color={Gray_Light_600} />
          </Button>
        </SwiperCarousel>
      </SwiperLayout>
    </section>
  );
};

const SwiperCarouselTitle = styled.div`
  padding: 0 16px;
`;

const SwiperLayout = styled.div`
  ${SWIPER_STYLE};

  .swiper {
    padding: 0 16px 24px;
  }
`;

const SwiperCarousel = styled(Swiper)`
  position: relative;
  width: auto;
  height: auto;
  min-height: 300px;
`;

const CardSwiperSlide = styled(SwiperSlide)`
  width: 29vw;
  padding-right: 2px;

  > * {
    width: 100%;
    height: auto;
  }
`;

const Button = styled.button`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 120px;
  bottom: 0;
  z-index: 100;
  width: 32px;
  height: 32px;
  background: ${Static_White};
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);

  &.swiper-button-prev::after,
  &.swiper-button-next::after {
    display: none;
  }

  &.swiper-button-disabled {
    visibility: hidden;
  }

  &.swiper-button-next {
    right: 4px;
    svg {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-prev {
    left: 4px;
  }
`;
