import { QuickMenu } from '../component/QuickMenu';
import { QuickMenuSkeleton } from '../component/QuickMenuSkeleton';
import { useQuickMenuUBL } from '../log/useQuickMenuUBL';
import { useQuickMenuService } from '../service/useQuickMenuService';

export const QuickMenuContainer = () => {
  const { isLoading, menuList } = useQuickMenuService();
  const { clickMenuItem } = useQuickMenuUBL();

  if (isLoading) return <QuickMenuSkeleton />;
  if (!menuList || menuList?.length === 0) return null;
  if (!menuList && process.env.NODE_ENV === 'development') {
    throw new Error('QuickMenu data가 유효하지 않습니다.');
  }
  return <QuickMenu list={menuList} onClick={clickMenuItem} />;
};
