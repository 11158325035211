import { ReactNode } from 'react';
import { Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { GetWebDdpCarousel_getWebDdpCarousel_item_item_list as UxGoodsCardItem } from '../graphql/ddp-carousel_types.2';

export interface ProductItemCarouselProps {
  title?: ReactNode;
  productItemList?: UxGoodsCardItem[] | null;
  renderProductCard: (item: UxGoodsCardItem, position: number) => ReactNode;
}

export const ProductItemCarousel = ({ title, productItemList, renderProductCard }: ProductItemCarouselProps) => {
  if (!productItemList?.length) return null;
  return (
    <ListSection>
      {title}
      <Carousel>
        {productItemList?.map((item, index) => (
          <CardSlide key={`${item.title}${title}`}>{renderProductCard(item, index)}</CardSlide>
        ))}
      </Carousel>
    </ListSection>
  );
};

const ListSection = styled.section`
  padding: 0 16px 24px;
`;

const Carousel = styled.ul`
  display: flex;
  margin: 0 -16px;
  overflow-x: auto;
  overflow-y: hidden;
  background: ${Static_White};

  li + li {
    margin-left: 2px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CardSlide = styled.li`
  flex: 0 0 auto;
  width: 29vw;
  max-width: 160px;

  :first-of-type {
    margin-left: 16px;
  }

  :last-of-type {
    margin-right: 16px;
  }
`;
