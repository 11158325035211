import styled from '@emotion/styled';

import { Gray_Light_White, Typography } from '@common/styles';

interface BannerCountProps {
  activeIndex: number;
  totalCount: number;
}

export const BannerCount = ({ activeIndex, totalCount }: BannerCountProps) => {
  if (!totalCount) {
    return null;
  }

  return (
    <Wrapper>
      <Text className={Typography.CAPTION_11_MEDIUM}>{activeIndex + 1}</Text>
      <Divider />
      <Text className={Typography.CAPTION_11_MEDIUM}>{totalCount}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 30px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 6px 10px;
  z-index: 1;
  min-width: 40px;
  height: 18px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
`;

const Divider = styled.span`
  width: 1px;
  height: 10px;
  margin: 2px 6px;
  background-color: ${Gray_Light_White};
  opacity: 0.2;
`;

const Text = styled.span`
  color: ${Gray_Light_White};
`;
