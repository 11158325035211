import React from 'react';
import styled from '@emotion/styled';

import { Gray_Light_White, web_max_width } from '@common/styles';

export interface HomeHeaderProps {
  children: React.ReactNode;
}

export const HomeHeader = ({ children }: HomeHeaderProps) => {
  return (
    <Header>
      <Wrapper>{children}</Wrapper>
    </Header>
  );
};

const Header = styled.nav`
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${Gray_Light_White};
`;

const Wrapper = styled.div`
  max-width: ${web_max_width}px;
  margin: auto;
`;
