import { useEffect, useState } from 'react';

import { isKakaotalk, isMobile } from '@common/device-manager';
import { useAirbridgeDeeplinks, WEB_TO_APP_DEEP_LINK } from '@common/marketing-tracker';
import { useBenefitLandingPopup } from '@common/overlay-manager';

export function useLandingBannerService() {
  const { open } = useBenefitLandingPopup();
  const { airbridgeDeeplinks } = useAirbridgeDeeplinks();
  const hide = useHide();
  const clickLandingBanner = () => {
    if (isMobile()) {
      airbridgeDeeplinks({
        deeplink: WEB_TO_APP_DEEP_LINK.HOME,
        ctaParams: {
          cta_param_1: 'kr_home_topbanner',
        },
      });
    } else {
      open();
    }
  };
  return { clickLandingBanner, hide };
}

function useHide() {
  const [hide, setHide] = useState(true);
  useEffect(() => {
    setHide(isKakaotalk());
  }, []);
  return hide;
}
