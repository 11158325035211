import { useGetQuickMenuQuery } from '../query/useGetQuickMenuQuery';

export function useQuickMenuService() {
  const { data, isLoading } = useGetQuickMenuQuery();

  return {
    menuList: data,
    isLoading,
  };
}
