import { forwardRef } from 'react';
import { MiniLoader } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

const LoadingContainer = styled.div`
  display: flex;
  height: 96px;
`;

export const ProductListLoading = forwardRef<HTMLDivElement>((_, ref) => (
  <LoadingContainer ref={ref}>
    <MiniLoader />
  </LoadingContainer>
));
