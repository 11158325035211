import { ReactNode } from 'react';
import { Gray_Light_200, Gray_Light_600, Gray_Light_900, Gray_Light_950 } from '@croquiscom/zds';
import styled from '@emotion/styled';
import Link from 'next/link';

import { Typography } from '@common/styles';

interface FirstPurchaseDealsProps {
  children: ReactNode;
  button: { text: string; landingUrl: string };
  onClickMore: () => void;
  isLazyLoad?: boolean;
}

export const FirstPurchaseDeals = ({ children, button, onClickMore }: FirstPurchaseDealsProps) => (
  <ListSection>
    {children}
    <Link href={button.landingUrl} passHref>
      <More className={Typography.BODY_15_SEMIBOLD} onClick={onClickMore}>
        {button.text}
      </More>
    </Link>
  </ListSection>
);

const ListSection = styled.div`
  padding: 12px 0 24px 0;
`;

export const Title = styled.h4`
  color: ${Gray_Light_950};
`;

export const SubTitle = styled.p`
  margin-top: 4px;
  color: ${Gray_Light_600};
`;

export const More = styled.a`
  display: flex;
  width: calc(100% - 32px);
  padding: 13px 20px;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${Gray_Light_200};
  color: ${Gray_Light_900};
`;
