import { HeaderContents } from '../components/HeaderContents';
import { HomeHeader } from '../components/HomeHeader';
import { useHomeHeaderUBL } from '../log/useHomeHeaderUBL';
import { useHeaderService } from '../services/useHeaderService';

export function HeaderContainer() {
  const { cart_count } = useHeaderService();
  const { clickHeaderContentsUBL } = useHomeHeaderUBL();

  const handleLogoClick = () => clickHeaderContentsUBL('zigzag_home');
  const handleCartClick = () => clickHeaderContentsUBL('cart');
  const handleSearchClick = () => clickHeaderContentsUBL('search');

  return (
    <HomeHeader
      children={
        <HeaderContents
          onLogoClick={handleLogoClick}
          onSearchClick={handleSearchClick}
          onCartClick={handleCartClick}
          cartItemsCount={cart_count}
        />
      }
    />
  );
}
