import { Gray_Light_600, Gray_Light_950 } from '@croquiscom/zds';
import styled from '@emotion/styled';

import { Typography } from '@common/styles';

interface SectionTitleProps {
  title: string;
  subTitle: string;
}

export const SectionTitle = ({ title, subTitle }: SectionTitleProps) => {
  return (
    <>
      <Title className={Typography.BODY_18_SEMIBOLD}>{title}</Title>
      <SubTitle className={Typography.BODY_13_REGULAR}>{subTitle}</SubTitle>
    </>
  );
};

export const Title = styled.p`
  color: ${Gray_Light_950};
`;

export const SubTitle = styled.p`
  margin: 8px 0 15px;
  color: ${Gray_Light_600};
`;
