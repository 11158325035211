import React, { useMemo } from 'react';
import { Gray_Light_950, IconShoppingbagBold32, Pink_Light_400, Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

interface CartButtonProps {
  count?: number;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const CartButton = ({ href, onClick, count = 0 }: CartButtonProps) => {
  const formatted_count = useMemo(() => {
    if (count < 0) {
      return 0;
    }
    if (count > 99) {
      return '99+';
    }
    return count;
  }, [count]);

  return (
    <CartWrapper aria-label='장바구니' href={href || '#'} onClick={onClick}>
      <StyledGeneralZpaySymbolIcon color={Gray_Light_950} />
      {Boolean(formatted_count) && <CartBadge>{formatted_count}</CartBadge>}
    </CartWrapper>
  );
};

const CartWrapper = styled.a`
  display: flex;
  margin: 0 0 0 auto;
  flex-shrink: 0;
  position: relative;
  width: 32px;
  height: 32px;
`;

const StyledGeneralZpaySymbolIcon = styled(IconShoppingbagBold32)`
  margin: 0 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
`;

const CartBadge = styled.div`
  padding: 5px;
  position: absolute;
  height: 16px;
  border-radius: 8px;
  background: ${Pink_Light_400};
  color: ${Static_White};
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -2px;
  right: -2px;
`;
