import { createContext, ReactNode, useContext } from 'react';

import { HomePath } from '../model/home-path';
import { PageId } from '../model/page-info';

export interface GNBConfigContextValue {
  path: HomePath;
  page_id?: PageId;
}

export const GNBConfigContext = createContext<GNBConfigContextValue>({
  path: {
    auth: { root: '/auth' },
    home: '/home',
    orders: '/orders',
    root: '/',
    cart: '/cart',
    categories: {
      root: '/categories',
    },
    picks: {
      root: '/picks',
    },
    my_page: {
      root: '/my-page',
    },
    my_review: {
      list: '/my-review/list',
      detail: '/review/detail',
      completed: '/review/completed',
    },
  },
  page_id: 'web_home',
});

export const useGNBConfig = () => useContext(GNBConfigContext);

export interface GNBConfigProviderProps extends GNBConfigContextValue {
  children: ReactNode;
}

/** @public */
export function GNBConfigProvider({ children, ...value }: GNBConfigProviderProps) {
  return <GNBConfigContext.Provider children={children} value={value} />;
}
