import { useRef } from 'react';
import styled from '@emotion/styled';

import { useIntersectionObserver } from '@common/hooks';
import { Loading } from '@common/overlay-manager';
import { VerticalProductCardVirtuosoGridContainer } from '@widgets/product-card';
import { PageId } from '@domains/home-common';

import { Footer } from '../components/Footer';
import { ProductListLoading } from '../components/Loading';
import { useCardListService } from '../services/useCardListService';

// 홈 상품 288개로 제한
const PRODUCT_ITEM_LIMIT = 288;

/** @public */
export function CardListContainer({ page_id, padding }: { page_id: PageId; padding?: string }) {
  const { fetchNextPage, hasNextPage, isLoading, product_list, onImpression, onClick, onLikeClick } =
    useCardListService({ page_id });

  const fetch_enabled = Boolean(hasNextPage && product_list.length < PRODUCT_ITEM_LIMIT);

  const target = useRef<HTMLDivElement>(null);

  useIntersectionObserver(
    {
      target,
      onIntersect: fetchNextPage,
      enabled: fetch_enabled,
      rootMargin: '300px',
    },
    [hasNextPage],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <VirtuosoGridContainerWarpper padding={padding}>
        <VerticalProductCardVirtuosoGridContainer
          increaseViewportBy={200}
          items={product_list ?? []}
          onImpression={onImpression}
          onClick={onClick}
          onLikeClick={onLikeClick}
          useWindowScroll
        />
      </VirtuosoGridContainerWarpper>
      {fetch_enabled ? <ProductListLoading ref={target} /> : <Footer />}
    </>
  );
}

const VirtuosoGridContainerWarpper = styled.section<{ padding?: string }>`
  padding: ${({ padding }) => padding ?? '0 0 24px'};
`;
