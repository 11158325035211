import { useUBL } from '@common/log-manager';

const commonLog = { navigation: 'home_ad', object_section: 'first_purchase' };

/** @public */
export const useFirstPurchaseDealsUBL = () => {
  const sendUBL = useUBL();

  const savedProductUBL = (
    category: 'save_product' | 'remove_saved_product',
    object_idx: number,
    object_id: string,
  ) => {
    sendUBL({
      ...commonLog,
      category,
      object_type: 'catalog',
      object_id,
      object_idx,
    });
  };

  const productImpressionUBL = (object_idx: number, object_id: string) => {
    sendUBL({
      ...commonLog,
      category: 'impression',
      object_type: 'catalog',
      object_id,
      object_idx,
    });
  };

  const productClickUBL = (object_idx: number, object_id: string) => {
    sendUBL({
      ...commonLog,
      category: 'click',
      object_type: 'catalog',
      object_id,
      object_idx,
    });
  };

  const moreClickUBL = () => {
    sendUBL({
      ...commonLog,
      category: 'click',
      object_type: 'button',
      object_id: 'see_all',
    });
  };

  return {
    productImpressionUBL,
    productClickUBL,
    savedProductUBL,
    moreClickUBL,
  };
};
