import { useUBL } from '@common/log-manager';
import { PAGE_ID_WEB_HOME } from '@domains/home-common';

export const useHomeHeaderUBL = () => {
  const sendUBL = useUBL();

  const clickHeaderContentsUBL = async (object_id: 'zigzag_home' | 'my_page' | 'search' | 'cart') => {
    sendUBL({
      category: 'click',
      navigation: PAGE_ID_WEB_HOME,
      object_section: 'top_bar',
      object_type: 'button',
      object_id,
    });
  };

  return {
    clickHeaderContentsUBL,
  };
};
