import { Gray_Light_150 } from '@croquiscom/zds';
import styled from '@emotion/styled';

export const QuickMenuSkeleton = () => (
  <MenuSkeleton>
    <ul>
      {Array.from({ length: 8 }).map((_, index) => (
        <li key={index} />
      ))}
    </ul>
  </MenuSkeleton>
);

export const MenuSkeleton = styled.section`
  overflow-x: hidden;
  ul {
    width: max-content;
    margin: 14px 0 20px 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 8px;
  }
  li {
    width: 64px;
    height: 64px;
    margin: 0 2px;
    background-color: ${Gray_Light_150};
    border-radius: 100%;
  }
`;
