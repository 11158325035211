import { DependencyList, useEffect, RefObject } from 'react';

interface Option {
  target?: RefObject<Element> | Element | null;
  onIntersect?: () => void;
  onOut?: () => void;
  threshold?: number | number[];
  rootMargin?: string;
  enabled?: boolean;
}

export function useIntersectionObserver(
  { target, onIntersect, onOut, threshold = 0, rootMargin = '0px', enabled = true }: Option,
  deps: DependencyList,
) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onIntersect?.();
          return;
        }

        onOut?.();
      },
      {
        rootMargin,
        threshold,
      },
    );

    const el = target instanceof Element ? target : target?.current;
    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [enabled, rootMargin, onIntersect, onOut, target, threshold, ...deps]);
}
