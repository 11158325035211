import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconShoppingbagBold32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={2} d='m22.59 10 1.312 14H8.098l1.313-14h13.178Z' />
      <path stroke='currentColor' strokeWidth={2} d='M13 14V8.5c0-2 1.5-3 3-3s3 1 3 3V14' />
    </Svg>
  );
};
export default SvgIconShoppingbagBold32;
