import { ReactNode, useEffect } from 'react';

import { airbridgeTracker } from '@common/marketing-tracker';
import { useHomeConfig, useHomeUBL } from '@domains/home-common';

import { Main } from '../components/Main';

export const MainContainer = ({ children }: { children: ReactNode }) => {
  const { pageviewUBL } = useHomeUBL();
  const { page_id } = useHomeConfig();

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }
    pageviewUBL(page_id, window.location.href);
    airbridgeTracker.events.send('airbridge.ecommerce.home.viewed');
  }, [page_id, pageviewUBL]);

  return <Main children={children} />;
};
