import { Gray_Light_100, Gray_Light_900, Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';
import Link from 'next/link';

import { Typography } from '@common/styles';

import { UxQuickMenuItemPart } from '../graphql/quick-menu-info_types.2';

interface QuickMenuProps<T> {
  list: T[];
  onClick: ({ title, item_index }: { title: string; item_index: number }) => void;
}

export const QuickMenu = ({ list, onClick }: QuickMenuProps<UxQuickMenuItemPart>) => {
  return (
    <QuickMenuSection>
      {list.map(({ name, image_url, landing_url }, index) => (
        <Link key={name.text} href={landing_url || ''} passHref>
          <MenuItem href={landing_url || ''} onClick={() => onClick({ title: name.text, item_index: index })}>
            <IconImage src={image_url.webp_url?.normal ?? image_url.url?.normal} alt={name.text} />
            <Name className={Typography.CAPTION_12_MEDIUM}>{name.text}</Name>
          </MenuItem>
        </Link>
      ))}
    </QuickMenuSection>
  );
};

const QuickMenuSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 14px 16px 32px 9px;
  background-color: ${Static_White};
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 68px;
  min-width: 68px;
`;

const IconImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background: ${Gray_Light_100};
`;

const Name = styled.span`
  width: 100%;
  margin-top: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  color: ${Gray_Light_900};
`;
