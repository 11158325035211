/** @public */
export enum PageIdEnum {
  /**
   * Home - 홈 탭 PLP
   */
  web_home = 'web_home',
  /**
   * Home - 브랜드 탭 PLP
   */
  web_brand = 'web_brand',
  /**
   * Home - 뷰티 탭 PLP
   */
  web_beauty_best = 'web_beauty_best',
}

/** @public */
export type PageId = keyof typeof PageIdEnum;

/** @public */
export enum HomePageName {
  home = 'home',
  brand = 'brand',
  beauty = 'beauty',
}

/** @public */
export type PageName = keyof typeof HomePageName;

/** @public */
export const convertToIdFromPageName = (name: HomePageName): PageIdEnum => {
  switch (name) {
    case HomePageName.brand:
      return PageIdEnum.web_brand;
    case HomePageName.beauty:
      return PageIdEnum.web_beauty_best;
    case HomePageName.home:
      return PageIdEnum.web_home;
    default:
      return PageIdEnum.web_home;
  }
};

/** @public */
export const convertToPageNameFromId = (id: PageId): PageName => {
  switch (id) {
    case PageIdEnum.web_beauty_best:
      return 'beauty';
    case PageIdEnum.web_brand:
      return 'brand';
    case PageIdEnum.web_home:
      return 'home';
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error('일치하는 case가 없습니다.');
      }
      return 'home';
  }
};

/** @public */
export function query_tab_name(query: unknown): HomePageName {
  if (typeof query === 'string') {
    if (query === HomePageName.beauty) {
      return HomePageName.beauty;
    }
    if (query === HomePageName.brand) {
      return HomePageName.brand;
    }
    if (query === HomePageName.home) {
      return HomePageName.home;
    }
  }
  return HomePageName.home;
}
