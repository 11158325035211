import { UxBrowsingType } from '../graphql/fragment_types.2';

/** @public */
export interface ProductPayload {
  shop_id?: string | null;
  shop_product_no?: string | null;
  browsing_type?: keyof typeof UxBrowsingType | null;
  catalog_product_id: string | null;
}

/** @public */
export function getObjectId<T extends ProductPayload>(product: T) {
  if (product?.browsing_type === 'NORMAL') {
    return `${product?.shop_id}$${product?.shop_product_no}`;
  } else {
    return `${product?.catalog_product_id}`;
  }
}

/** @public */
export function getObjectType<T extends ProductPayload>(product: T) {
  return product?.browsing_type === 'NORMAL' ? 'goods' : 'catalog';
}
