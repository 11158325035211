import styled from '@emotion/styled';
import Link from 'next/link';

import { Gray_Light_White } from '@common/styles';

import { CartButton } from './CartButton';
import { SearchButton } from './SearchButton';

const zigzagLogo = 'https://cf.image-farm.s.zigzag.kr/original/cms/2024/05/02/202405020543198430_071960.webp';

export interface HeaderContentsProps {
  onLogoClick: () => void;
  onSearchClick: () => void;
  onCartClick: () => void;
  cartItemsCount?: number;
}

export const HeaderContents = ({ onLogoClick, onSearchClick, onCartClick, cartItemsCount }: HeaderContentsProps) => {
  return (
    <BaseNavigation>
      <LeftContent>
        <Link href='/' passHref>
          <HeaderLeftButton aria-label='홈' href='/' onClick={onLogoClick}>
            <Logo src={zigzagLogo} alt='지그재그 로고' />
          </HeaderLeftButton>
        </Link>
      </LeftContent>
      <HeaderRightButtons>
        <SearchButton onClickSearchIcon={onSearchClick} />
        <CartButton href='/cart' onClick={onCartClick} count={cartItemsCount} />
      </HeaderRightButtons>
    </BaseNavigation>
  );
};

const BaseNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 4px 12px 4px 16px;
  background: ${Gray_Light_White};
`;

const HeaderRightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  & > a {
    margin: 0;
    display: flex;
    box-sizing: content-box;
    padding: 6px;
    justify-content: center;
    align-items: center;

    &:last-of-type {
      padding: 0;
      margin: 6px 0 6px 6px;
    }
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLeftButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 4px;
  align-items: center;
  cursor: pointer;
`;
const Logo = styled.img`
  width: auto;
  height: 22px;
`;
