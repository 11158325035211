import { merge } from 'lodash/fp';

import { GetWebBannerList_web_banner_list_item_list } from '../graphql/banner_types.2';
import { useHomeBannerUBL } from '../log/useHomeBannerUBL';
import { useGetBanner } from '../query/useBannerQuery';
import { getLandingUrl } from '../utils/getLandingUrl';

export type BannerItem = GetWebBannerList_web_banner_list_item_list & {
  index: number;
};

export const useMainBannerService = () => {
  const { data: bannerListData } = useGetBanner();
  const bannerList = bannerListData ?? [];
  const { bannerClickUBL, bannerImpressionUBL } = useHomeBannerUBL();

  const goLandingLink = (landing_url: string | null) => {
    if (typeof landing_url !== 'string') {
      console.error('landing_url이 string이 아닙니다');
      return;
    }

    const web_landing_link = getLandingUrl(landing_url);
    if (typeof web_landing_link !== 'string') {
      return;
    }

    window.open(web_landing_link, '_blank');
  };

  const handleBannerItemClick = (item: BannerItem) => {
    bannerClickUBL(item);
    goLandingLink(item.landing_url);
  };

  const handleBannerItemIntersect = (index: number) => {
    bannerImpressionUBL(merge(bannerList[index], { index }));
  };

  return {
    goLandingLink,
    bannerList: bannerList ?? [],
    handleBannerItemClick,
    handleBannerItemIntersect,
  };
};
