import { useQuery } from 'react-query';

import { getWebDdpCarousel } from '../graphql/ddp-carousel.2';

export const useGetFirstPurchaseDeals = () => {
  const isNaverBot =
    typeof window !== 'undefined' && (/(Yeti)/i.test(navigator.userAgent) || /(Naverbot)/i.test(navigator.userAgent));

  return useQuery('getFirstPurchaseDeals', () => getWebDdpCarousel(), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled: !isNaverBot,
  });
};
