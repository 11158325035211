import { useUBL } from '@common/log-manager';

export function useQuickMenuUBL() {
  const sendUBL = useUBL();
  const clickMenuItem = ({ title, item_index }: { title: string; item_index: number }) => {
    sendUBL({
      category: 'click',
      navigation: 'home_ad',
      object_section: 'quick_menu_circle',
      object_type: 'image',
      object_id: title,
      object_idx: item_index,
    });
  };
  return { clickMenuItem };
}
