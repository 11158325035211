import { IconSearchBold } from '@croquiscom-pvt/zds';
import Link from 'next/link';

interface SearchButtonProps {
  onClickSearchIcon: () => void;
}
export const SearchButton = ({ onClickSearchIcon }: SearchButtonProps) => (
  <Link href='/search' passHref>
    <a aria-label='검색' href='/search' onClick={onClickSearchIcon}>
      <IconSearchBold v3Color='gray950' />
    </a>
  </Link>
);
