import { Typography } from '@common/styles';
import { Beige_Light_100, Gray_Light_900, Static_White } from '@croquiscom/zds';
import styled from '@emotion/styled';

interface LandingBannerProps {
  onClick?: () => void;
  src?: string;
  alt?: string;
  buttonText?: string;
  hidden?: boolean;
}

/** @public */
export function LandingBanner(props: LandingBannerProps) {
  return (
    <Container onClick={props.onClick} style={{ height: props.hidden ? 0 : 54 }}>
      <LandingImage src={props.src} alt={props.alt} />
      <GoToApp className={Typography.CAPTION_12_SEMIBOLD}>{props.buttonText}</GoToApp>
    </Container>
  );
}

const Container = styled.button`
  background: ${Beige_Light_100};
  width: 100%;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LandingImage = styled.img`
  width: auto;
  height: 25px;
`;

const GoToApp = styled.span`
  display: inline-block;
  padding: 5px 12px;
  background-color: ${Gray_Light_900};
  color: ${Static_White};
  border-radius: 100px;
`;
