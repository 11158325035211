import { toQueryString } from '@croquiscom-pvt/web2app';

import { airbridgeTracker } from './tracker';

export const WEB_TO_APP_DEEP_LINK = {
  HOME: 'zigzag://open/',
  GNP: 'zigzag://open/gnp',
  PICK: 'zigzag://open/my_goods',
  LOGIN: 'zigzag://open/my_page',
  MYPAGE: 'zigzag://open/my_page',
  STORE_HOME: 'zigzag://open/shopping_malls',
  SEARCH: 'zigzag://open/goods_search',
  EVENT: 'zigzag://open/simple_browser',
};

interface UrlInfo {
  page_scheme: 'product_detail' | 'browser_product_review' | 'simple_browser';
  data: object;
}

interface AirbridgeDeepLinkParams {
  // onclick 함수 사용 시 click, 유저 액션 없이 사용 시 redirect
  type?: 'click' | 'redirect';
  deeplink: string;
  ctaParams: {
    cta_param_1: string;
    cta_param_2?: string;
    cta_param_3?: string;
  };
}

interface DeeplinkBuilder {
  (url_info: UrlInfo): string;
}

interface AirbridgeDeeplinksHandler {
  (params: AirbridgeDeepLinkParams): void;
}

interface AirbridgeDeeplinks {
  buildDeeplink: DeeplinkBuilder;
  airbridgeDeeplinks: AirbridgeDeeplinksHandler;
}

const getDeeplinkBuilder = (): DeeplinkBuilder => {
  const buildDeeplink = (url_info: UrlInfo) => {
    return `zigzag://open/${url_info.page_scheme}?${toQueryString(url_info.data)}`;
  };

  return buildDeeplink;
};

const getAirbridgeDeeplinksHandler = (): AirbridgeDeeplinksHandler => {
  const airbridgeDeeplinks = ({ type = 'click', deeplink, ctaParams }: AirbridgeDeepLinkParams) =>
    airbridgeTracker.openDeeplink({
      type,
      deeplinks: {
        ios: deeplink,
        android: deeplink,
        desktop: deeplink,
      },
      ctaParams: {
        cta_param_1: ctaParams.cta_param_1,
        cta_param_2: ctaParams.cta_param_2 || '',
        cta_param_3: ctaParams.cta_param_3 || '',
      },
      fallbacks: {
        ios: 'itunes-appstore',
        android: 'google-play',
      },
    });

  return airbridgeDeeplinks;
};

export const getAirbridgeDeeplinks = (): AirbridgeDeeplinks => {
  const buildDeeplink = getDeeplinkBuilder();
  const airbridgeDeeplinks = getAirbridgeDeeplinksHandler();

  return { buildDeeplink, airbridgeDeeplinks };
};

export const useAirbridgeDeeplinks = (): AirbridgeDeeplinks => {
  return getAirbridgeDeeplinks();
};
