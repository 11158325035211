import { gql_request, GqlRequestOptions } from '@common/api-request';

import * as types from './banner_types.2';

export function getWebBannerList(variable: types.GetWebBannerListVariables, options?: GqlRequestOptions) {
  const query = 'fragment WebBannerGroupPart on UxImageBannerGroup { id position type aspect_ratio is_auto_rolling update_interval item_list { ...WebBannerItemPart } } fragment WebBannerItemPart on UxImageBanner { id image_url landing_url position main_title_first main_title_second sub_title log } query GetWebBannerList($type: String) { web_banner_list(type: $type) { ...WebBannerGroupPart } }';
  return gql_request<types.GetWebBannerList, types.GetWebBannerListVariables>('GetWebBannerList', query, variable, { use_consumer: true, ...options });
}

export function getWebDDPRollingImageBanner(variable: types.GetWebDDPRollingImageBannerVariables, options?: GqlRequestOptions) {
  const query = 'fragment DDPRollingBanner on DdpRollingImageBanner { id type aspect_ratio show_button item_list { ...DDPBanner } } fragment DDPBanner on DdpBanner { id title_first { ...DdpText } title_second { ...DdpText } subtitle { ...DdpText } image { url webp_url } landing_url badge_types log } fragment DdpText on DdpText { text color is_html } query GetWebDDPRollingImageBanner($id: ID!) { web_ddp_rolling_image_banner(id: $id) { ...DDPRollingBanner } }';
  return gql_request<types.GetWebDDPRollingImageBanner, types.GetWebDDPRollingImageBannerVariables>('GetWebDDPRollingImageBanner', query, variable, { use_consumer: true, ...options });
}
