import { VerticalProductCardWithItem } from '@widgets/product-card';

import { FirstPurchaseDeals } from '../components/FirstPurchaseDeals';
import { SectionTitle } from '../components/SectionTitle';
import { WebProductItemCarousel } from '../components/WebProductItemCarousel';
import { useFirstPurchaseDeals } from '../service/useFirstPurchaseDeals';

export const FirstPurchaseDealsContainer = () => {
  const { title, subTitle, itemList, button, onImpressionProduct, onClickProduct, onSaveProduct, onClickMore } =
    useFirstPurchaseDeals();

  if (!itemList?.length) return null;

  return (
    <FirstPurchaseDeals button={button} onClickMore={onClickMore} isLazyLoad>
      <WebProductItemCarousel
        title={<SectionTitle title={title} subTitle={subTitle} />}
        productItemList={itemList}
        renderProductCard={(item, position) => (
          <VerticalProductCardWithItem
            key={item.catalog_product_id}
            item={item}
            onImpression={(item) => onImpressionProduct(item, position)}
            onClick={(item) => onClickProduct(item, position)}
            onLikeClick={(checked, item) => onSaveProduct(checked, item, position)}
            size='small'
          />
        )}
      />
    </FirstPurchaseDeals>
  );
};
