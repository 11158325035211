import { getRuntimeConfigValue } from '@common/next-runtime-config';

export function getLandingUrl(landing_url: string) {
  const landingUrlWithDeepLinkExcluded = getLandingUrlWithDeepLinkExcluded(landing_url);
  const landingUrlWithAppUrlConfig = getLandingUrlWithAppUrlConfig(landingUrlWithDeepLinkExcluded);

  return landingUrlWithAppUrlConfig;
}

function getLandingUrlWithDeepLinkExcluded(landing_url: string) {
  if (isDeepLink(landing_url)) {
    return extractWebLandingUrl(landing_url);
  }

  return landing_url;
}

function isDeepLink(url?: string) {
  return url?.startsWith('zigzag://');
}

function extractWebLandingUrl(landing_url: string) {
  const url_part = landing_url.split('?')?.[1];
  return new URLSearchParams(url_part).get('url');
}

function getLandingUrlWithAppUrlConfig(landing_url: string | null) {
  if (!landing_url) {
    return null;
  }

  return landing_url.replace('https://store.zigzag.kr', getAppUrl());
}

function getAppUrl() {
  try {
    const app_url = getRuntimeConfigValue('appUrl') as string;

    if (!app_url) {
      throw new Error('appUrl is not defined');
    }

    return app_url;
  } catch (err) {
    return 'https://store.zigzag.kr';
  }
}
