import { useTracker } from '@common/log-manager';

import { PageId, PageIdEnum } from '../models/page-info';

type HomeNavigationName = 'home_ad' | 'home_brand' | 'home_beauty';

function convertToNavigationFromPageId(page_id: PageId): HomeNavigationName {
  switch (page_id) {
    case PageIdEnum.web_beauty_best:
      return 'home_beauty';
    case PageIdEnum.web_brand:
      return 'home_brand';
    case PageIdEnum.web_home:
      return 'home_ad';
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error('일치하는 case가 없습니다.');
      }
      return 'home_ad';
  }
}

/** @public */
export const useHomeUBL = () => {
  const tracker = useTracker();

  const pageviewUBL = (page_id: PageId, page_url: string) => {
    tracker.addLog({
      category: 'pageview',
      navigation: convertToNavigationFromPageId(page_id),
      navigation_sub: { page_url },
      data: {
        is_opened: true,
      },
    });
  };

  const savedProductUBL = (
    category: 'save_product' | 'remove_saved_product',
    page_id: PageId,
    catalog_product_id: string,
  ) => {
    tracker.addLog({
      category,
      navigation: convertToNavigationFromPageId(page_id),
      object_type: 'catalog',
      object_id: catalog_product_id,
    });
  };

  const appDownloadClickUBL = async (page_id: PageId) => {
    await tracker.sendImmediately({
      category: 'click',
      navigation: convertToNavigationFromPageId(page_id),
      object_type: 'banner',
      object_id: 'app_download',
    });
  };

  const productImpressionUBL = (page_id: PageId, object_type: string, object_id: string, object_idx: number) => {
    tracker.addLog({
      category: 'impression',
      navigation: convertToNavigationFromPageId(page_id),
      object_type,
      object_id,
      object_idx,
    });
  };

  const productClickUBL = async (page_id: PageId, object_type: string, object_id: string, object_idx: number) => {
    await tracker.sendImmediately({
      category: 'click',
      navigation: convertToNavigationFromPageId(page_id),
      object_type,
      object_id,
      object_idx,
    });
  };

  return {
    pageviewUBL,
    appDownloadClickUBL,
    productImpressionUBL,
    productClickUBL,
    savedProductUBL,
  };
};
