import { useUBL } from '@common/log-manager';

import { BannerItem } from '../services/useMainBannerService';

export const useHomeBannerUBL = () => {
  const sendUBL = useUBL();

  const commonLog = { object_type: 'banner', navigation: 'home_ad' };

  const bannerImpressionUBL = ({ id, index, landing_url }: BannerItem) =>
    sendUBL({
      ...commonLog,
      category: 'impression',
      object_id: id,
      object_idx: index,
      data: { landing_url },
    });

  const bannerClickUBL = ({ id, index, landing_url }: BannerItem) =>
    sendUBL({
      ...commonLog,
      category: 'click',
      object_id: id,
      object_idx: index,
      data: { landing_url },
    });

  return {
    bannerImpressionUBL,
    bannerClickUBL,
  };
};
