import { NextSeo, SocialProfileJsonLd } from 'next-seo';

import { useSeoService } from '../services/useSeoService';

export const SeoContainer = () => {
  const { openGraph, structuredSocialProfileJsonLd } = useSeoService();

  return (
    <>
      <NextSeo
        title='지그재그 스토어'
        description='4,000만 여성이 선택한 올인원 쇼핑 앱 지그재그 - 제가 알아서 살게요.'
        openGraph={openGraph}
      />
      <SocialProfileJsonLd type='Person' {...structuredSocialProfileJsonLd} />
    </>
  );
};
